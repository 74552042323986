import {
  faBriefcase,
  faClipboardList,
  faFire,
  faPen, faPhone,
  faPowerOff,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import { TEAMS_BLOCKED } from '../../../store/types/actions.types';
import { mapGetters } from 'vuex';

export default {
  name: 'CallCardTeamDetail',
  components: {
    CallButton
  },
  props: {
    objCard: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      dataIconLock: faPowerOff,
      dataIconReading: faPen,
      dataIconTeams: faUser,
      dataIconUserAll: faUsers,
      dataIconBriefcase: faBriefcase,
      dataIconPhone: faPhone,
      dataIconFire: faFire,
      dataIconClipboardCheck: faClipboardList
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin'
    }),
    computedCallMode () {
      switch (this.objCard?.call_mode) {
        case 'asterisk':
          return 'Asterisk';
        case 'custom':
          return 'Custom';
        case 'manual':
          return 'Manual';
      }
      return this.objCard?.call_mode;
    }
  },
  methods: {
    async clickBlocked () {
      try {
        await this.$store.dispatch(`team/${TEAMS_BLOCKED}`, {
          teamId: this.objCard.id,
          is_blocked: !this.objCard.is_active
        });
        this.objCard.is_active = !this.objCard.is_active;
        this.$Notify({
          title: 'Team',
          message: `Successfully  ${!this.objCard.is_active ? 'locked' : 'unlocked'}`,
          type: 'success'
        });
      } catch (e) {
        throw Error('Ошибка блокировки');
      }
    }
  },
  render (h) {
    return (
      <div class="card-wrapper">
        <div class="card-project">
          <div class="card-project__header card-project__header--between">
            <div class="card-project__header-info">
              <p>ID: {this.objCard?.id}</p>
              <p>|</p>
              <p>
                {/* eslint-disable-next-line camelcase */}
                {this.$options.filters.dateFilter(this.objCard?.created_at)}
              </p>
            </div>
            <div class="card-project__btn-wrapper">
              {
                this.computedIsAdmin ? [
                  <call-button type={this.objCard.is_active ? 'danger' : 'success'} plain icon={this.dataIconLock}
                    onClick={this.clickBlocked}>
                    &nbsp;{this.objCard.is_active ? 'Deactivate' : 'Activate'}
                  </call-button>,
                  <call-button type="success" plain icon={this.dataIconReading} onClick={() => this.$emit('on-update')}>
                    &nbsp;Edit
                  </call-button>
                ] : null
              }
            </div>
          </div>
          <div class="card-project__body">
            <p class="card-project__title">
              {this.objCard?.name}
            </p>
            <p class="card-project__info">
              <font-awesome-icon icon={this.dataIconTeams}/>
              &nbsp;{this.objCard?.lead_name}
            </p>
            <p class="card-project__info">
              <font-awesome-icon icon={this.dataIconPhone}/>
              &nbsp;{this.computedCallMode}
            </p>

          </div>
        </div>

        <div class="card-project__info-wrapper--vertical">
          <div class="card-project__info-block card-project card-project--center">
            <div class="success icon">
              <font-awesome-icon
                icon={this.dataIconUserAll}/>
            </div>
            <p>{this.objCard?.operators_count || 0} Operators</p>
          </div>
          <div class="card-project__info-block card-project card-project--center">
            <div class="success icon">
              <font-awesome-icon
                icon={this.dataIconBriefcase}/>
            </div>
            <p>{(this.objCard?.projects_active_count || 0) + (this.objCard?.projects_closed_count || 0)} Projects</p>
          </div>
        </div>

        <div class="card-project__info-wrapper--horinzontal">
          <div class="card-project__info-block card-project">
            <div class="card-project__info-block__part">
              <div class="warning icon">
                <font-awesome-icon
                  icon={this.dataIconFire}/>
              </div>
              <div class="card-project__info-block__line">
                <p>Open Tasks</p>
                <p>{(this.objCard?.open_free_tasks_count || 0) + (this.objCard?.open_assigned_tasks_count || 0)}</p>
              </div>
              <div class="card-project__info-block__line">
                <span>Free</span>
                <span>{this.objCard?.open_free_tasks_count || 0}</span>
              </div>
              <div class="card-project__info-block__line success">
                <span>Assigned</span>
                <span>{this.objCard?.open_assigned_tasks_count || 0}</span>
              </div>
            </div>
            <div class="card-project__info-block__part">
              <div class="success icon">
                <font-awesome-icon
                  icon={this.dataIconClipboardCheck}/>
              </div>
              <div class="card-project__info-block__line">
                <p>Closed Tasks</p>
                <p>{(this.objCard?.auto_closed_free_tasks_count || 0) + (this.objCard?.auto_closed_assigned_tasks_count || 0) + (this.objCard?.manually_closed_tasks_count || 0)}</p>
              </div>
              <div class="card-project__info-block__line">
                <span>Auto Free</span>
                <span>{this.objCard?.auto_closed_free_tasks_count || 0}</span>
              </div>
              <div class="card-project__info-block__line success">
                <span>Auto Assigned</span>
                <span>{this.objCard?.auto_closed_assigned_tasks_count || 0}</span>
              </div>
              <div class="card-project__info-block__line success">
                <span>Auto Assigned VIP</span>
                <span>{this.objCard?.auto_closed_assigned_vip_tasks_count || 0}</span>
              </div>
              <div class="card-project__info-block__line danger">
                <span>Manually</span>
                <span>{this.objCard?.manually_closed_tasks_count || 0}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
};
