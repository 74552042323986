<template>
  <div>
    <call-card-team-detail
      :obj-card="dataTeamObj"
      @on-update="dataShowUpdateTeam = true;" />
    <call-table-operators
      v-if="computedTeamId"
      type="team"
      :team-id="computedTeamId" />
    <call-modal-update-team
      v-model="dataShowUpdateTeam"
      :team-id="dataTeamObj.id" />
  </div>
</template>

<script>
import CallCardTeamDetail from '../../../components/common/cards/teamDetailCard';
import { TEAMS_GET } from '../../../store/types/actions.types';
import CallTableOperators from '../../../components/desktop/CallTableOperators';
import CallModalUpdateTeam from '../../../components/common/modals/CallUpdateTeam';

export default {
  name: 'PageTeamsDetail',
  components: { CallModalUpdateTeam, CallTableOperators, CallCardTeamDetail },
  data () {
    return {
      dataTeamObj: {},
      dataShowUpdateTeam: false
    };
  },
  computed: {
    computedTeamId () {
      return this.dataTeamObj.id;
    }
  },
  async created () {
    try {
      const { team = {} } = await this.$store.dispatch(`team/${TEAMS_GET}`, {
        params: {
          id: this.$route.params?.id
        }
      });
      this.dataTeamObj = team;
    } catch (e) {

    }
  }
};
</script>
